import React from 'react'
import { graphql } from 'gatsby'
import { Blog as BlogPageComponent } from '../components/pages'

const Blog: React.FC<Props> = ({ data, location }) => {
  const blogCollection = data?.allContentfulBlogPost?.nodes.map((blogNode: ContentfulBlogNode) => {
    return {
      title: blogNode?.title,
      slug: blogNode?.slug,
      gatsbyImageData: blogNode?.heroImage?.gatsbyImageData,
      subtitle: blogNode?.subtitle,
      publishDate: new Date(blogNode?.publishDate),
      borderColor: blogNode?.borderColor
    }
  })

  return (
    <BlogPageComponent
      headerImageGatsbyImageData={data?.contentfulBlogPage?.headerImage?.gatsbyImageData}
      introText={data.contentfulBlogPage.introText.introText}
      blogCollection={blogCollection}
      location={location}
    />
  )
}

export default Blog

export const query = graphql`
  query BlogPageQuery {
    contentfulBlogPage(contentful_id: { eq: "guB3EN5FW21kr0BBk0Uq7" }) {
      headerImage {
        id
        gatsbyImageData(layout: CONSTRAINED, height: 100)
      }
      introText {
        introText
      }
    }
    allContentfulBlogPost(sort: { order: DESC, fields: publishDate }) {
      nodes {
        title
        subtitle
        publishDate
        borderColor
        slug
        heroImage {
          id
          gatsbyImageData(layout: CONSTRAINED, height: 480)
        }
      }
    }
  }
`

interface Props {
  data: {
    contentfulBlogPage: {
      headerImage: {
        gatsbyImageData: any
      }
      introText: {
        introText: string
      }
    }
    allContentfulBlogPost: {
      nodes: ContentfulBlogNode[]
    }
  }
  location: any
}

interface ContentfulBlogNode {
  title: string
  subtitle: string
  publishDate: string
  borderColor: string
  slug: string
  heroImage: {
    gatsbyImageData: any
  }
}
